import PageHeader from '../../components/PageHeader/PageHeader';
import StoreProduct, { AddStoreProduct, StoreProductLoader } from './components/AddStoreProduct';
import AddStoreProductModal from './components/AddStoreProductModal';
import { useStore } from '../../store/useStore';
import { useEffect } from 'react';
export default function Store() {
  const getPublicProducts = useStore((state) => state.products.getPublicProducts);
  const publicProducts = useStore((state) => state.products.publicProducts);
  const isLoading = useStore((state) => state.products.isLoading);
  const company = useStore((state) => state.company.company);

  useEffect(() => {
    getPublicProducts();
  }, [company]);
  return (
    <div>
      <div>
        <PageHeader title="Loja" />
        <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5">
          <AddStoreProduct />
          {!isLoading
            ? publicProducts.map(({ name, id, description, image_url, price }) => (
                <StoreProduct
                  key={id}
                  id={id}
                  name={name}
                  description={description}
                  image_url={image_url}
                  price={price}
                />
              ))
            : Array.from({ length: 4 }).map((_, index) => (
                <StoreProductLoader key={index} position={index} />
              ))}
        </div>
      </div>
      <AddStoreProductModal />
    </div>
  );
}
