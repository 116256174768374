import React, { useEffect } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useStore } from '../../store/useStore';
import ProductsTable from './components/ProductsTable';
import SearchFilter from '../../components/Filters/SearchFilter';
import OutlinedButton from '../../components/FormComponents/Buttons/OutlinedButton';
import { PlusIcon } from '../../assets/imgs';
import { Colors } from '../../utils/colors';
import CreateProductModal from './components/CreateProductModal';
import UpdateProductModal from './components/UpdateProductModal';
import DeleteProductModal from './components/DeleteProductModal';
import AddStoreProductModal from '../Store/components/AddStoreProductModal';

const Products = () => {
  const getProducts = useStore((state) => state.products.getProducts);
  const products = useStore((state) => state.products.products);
  const isCreateProductVisible = useStore((state) => state.products.isCreateProductVisible);
  const isUpdateProductVisible = useStore((state) => state.products.isUpdateProductVisible);
  const toggleUpdateProductModal = useStore((state) => state.products.toggleUpdateProductModal);
  const toggleIsCreateProductVisible = useStore(
    (state) => state.products.toggleIsCreateProductVisible
  );
  const toggleDeleteProductModal = useStore((state) => state.products.toggleDeleteProductModal);
  const isDeleteProductVisible = useStore((state) => state.products.isDeleteProductVisible);
  const resetFilters = useStore((state) => state.products.resetFilters);

  const isLoading = useStore((state) => state.products.isLoading);
  const filters = useStore((state) => state.products.filters);

  const setFilter = useStore((state) => state.products.setFilter);
  const handleChange = (text) => {
    setFilter('search', text);
  };

  useEffect(() => {
    resetFilters();
  }, []);

  useEffect(() => {
    getProducts();
  }, [filters.search]);
  return (
    <div>
      <PageHeader title={'Meus Produtos'} />
      <div className="flex gap-4 flex-wrap">
        <SearchFilter onChange={handleChange} className="w-40" />
        <OutlinedButton onClick={toggleIsCreateProductVisible} className="px-5">
          <div className="flex gap-3 items-center h-[40px]">
            <div className="font-semibold">Novo produto</div>
            <PlusIcon color={Colors.Orange} />
          </div>
        </OutlinedButton>
      </div>
      <div className="mt-5">
        <ProductsTable data={products} loading={isLoading} />
      </div>
      <CreateProductModal show={isCreateProductVisible} onClose={toggleIsCreateProductVisible} />
      <UpdateProductModal show={isUpdateProductVisible} onClose={toggleUpdateProductModal} />
      <DeleteProductModal show={isDeleteProductVisible} onClose={toggleDeleteProductModal} />
      <AddStoreProductModal />
    </div>
  );
};

export default Products;
