export const ERRORS = {
  'S-000': 'Operação concluída com sucesso',
  'E-000': 'Empresa inexistente',
  'E-008': 'Empresa sem pacotes para realizar ação',
  'E-009': 'Credencias inválidas',
  'E-010': 'Email já cadastrado',
  'E-011': 'NUIT já cadastrado',
  'E-012': 'Telefone já cadastrado',
  'E-013': 'Cliente não encontrado',
  'E-014': 'Código inválido',
  'E-015': 'Código expirado',
  'E-016': 'Conta desativada',
  'E-017': 'Não foi possível retornar o OTP',
  'E-018': 'Dados inexistentes',
  'E-019': 'Dados inválidos',
  'E-020': 'Dados inválidos',
  'E-021': 'Funcionalidade não implementada',
  'E-022': 'Um cliente com esses dados já foi cadastrado',
  'E-023': 'Saldo insuficiente para esta operação. O saldo não deve ser inferior a -90',
  'E-024': 'Saldo insuficiente para esta operação.',
  'E-025': 'O saldo está indefinido',
  'E-026': 'Erro ao obter carteira da empresa',
  'E-027': 'Dados inválidos ou incompletos',
  'E-028': 'Dados inválidos',
  'E-500': 'Erro interno do servidor'
};
